import React from 'react';

import {
  MainLayout,
  TradingLayout,
  UserDetailsLayout,
} from '@trader/containers';
import { ERouteType, ICustomRouteObject } from '@trader/navigation';
import { Progress } from '@trader/components';
import { paths } from '@trader/constants';
import { Dashboard } from '@trader/pages';

const AssetsLazy = React.lazy(() =>
  import('@trader/pages').then(({ Assets }) => ({
    default: Assets,
  }))
);
const MuliBandsLazy = React.lazy(() =>
  import('@trader/pages').then(({ MuliBands }) => ({
    default: MuliBands,
  }))
);
const AccountStatementReportLazy = React.lazy(() =>
  import('@trader/pages').then(({ AccountStatementReport }) => ({
    default: AccountStatementReport,
  }))
);
const MyAccountLazy = React.lazy(() =>
  import('@trader/pages').then(({ MyAccount }) => ({
    default: MyAccount,
  }))
);
const MyHistoryLazy = React.lazy(() =>
  import('@trader/pages').then(({ MyHistory }) => ({
    default: MyHistory,
  }))
);
const SettingsLazy = React.lazy(() =>
  import('@trader/pages').then(({ Settings }) => ({
    default: Settings,
  }))
);
const TradeLazy = React.lazy(() =>
  import('@trader/pages').then(({ Trade }) => ({
    default: Trade,
  }))
);

export const mainRoutes: ICustomRouteObject[] = [
  {
    id: 'mainRoutes_Root',
    path: paths.main.root,
    element: <MainLayout />,
    routeType: ERouteType.Authorized,
    children: [
      {
        id: 'mainRoutes_Root_Dashboard',
        index: true,
        element: <Dashboard />,
        routeType: ERouteType.Authorized,
      },
    ],
  },
  {
    id: 'mainRoutes_MainLayout',
    path: paths.main.dashboard,
    element: <MainLayout />,
    routeType: ERouteType.Authorized,
    children: [
      {
        id: 'mainRoutes_MainLayout_Dashboard',
        index: true,
        element: <Dashboard />,
        routeType: ERouteType.Authorized,
      },
    ],
  },
  {
    id: 'mainRoutes_TradingLayout',
    path: paths.main.trading,
    routeType: ERouteType.Authorized,
    element: <TradingLayout />,
    children: [
      {
        id: 'mainRoutes_TradingLayout_Dashboard',
        index: true,
        element: <Dashboard />,
        routeType: ERouteType.Authorized,
      },
      {
        id: 'mainRoutes_TradingLayout_Trade',
        path: paths.main.trade,
        element: (
          <React.Suspense fallback={<Progress />}>
            <TradeLazy />
          </React.Suspense>
        ),
        routeType: ERouteType.Authorized,
      },
    ],
  },
  {
    id: 'mainRoutes_Assets',
    path: paths.main.assets,
    routeType: ERouteType.Authorized,
    element: (
      <React.Suspense fallback={<Progress />}>
        <AssetsLazy />
      </React.Suspense>
    ),
  },
  {
    id: 'mainRoutes_Muli_Bands',
    path: paths.main.muliBands,
    routeType: ERouteType.Authorized,
    element: (
      <React.Suspense fallback={<Progress />}>
        <MuliBandsLazy />
      </React.Suspense>
    ),
  },
  {
    id: 'mainRoutes_AccountStatementReport',
    path: paths.main.accountStatementReport,
    routeType: ERouteType.Authorized,
    element: (
      <React.Suspense fallback={<Progress />}>
        <AccountStatementReportLazy />
      </React.Suspense>
    ),
  },
  {
    id: 'mainRoutes_UserDetails',
    path: paths.main.userDetails,
    routeType: ERouteType.Authorized,
    element: <UserDetailsLayout />,
    children: [
      {
        id: 'mainRoutes_UserDetails_MyAccount',
        index: true,
        path: paths.main.myAccount,
        element: (
          <React.Suspense fallback={<Progress />}>
            <MyAccountLazy />
          </React.Suspense>
        ),
        routeType: ERouteType.Authorized,
      },
      {
        id: 'mainRoutes_UserDetails_MyHistory',
        path: paths.main.myHistory,
        element: (
          <React.Suspense fallback={<Progress />}>
            <MyHistoryLazy />
          </React.Suspense>
        ),
        routeType: ERouteType.Authorized,
      },
      {
        id: 'mainRoutes_UserDetails_Settings',
        path: paths.main.settings,
        element: (
          <React.Suspense fallback={<Progress />}>
            <SettingsLazy />
          </React.Suspense>
        ),
        routeType: ERouteType.Authorized,
      },
    ],
  },
];
